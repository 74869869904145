<template>
  <div>
    <component ref="model" :is="getComonent(config.modelName||'Model-1')" backdrop="static" @ready="modelReady" @Hide="onhide">
      <div style="position:relative">
        <h6>
          基本设置
        </h6>
        <div class="" style="position:absolute;top:0;right:0;padding:5px;cursor:pointer;" @click="$refs.model.hideModal()">
          <i class="fa fa-times"></i>
        </div>
        <div>
          <div class="card-body p-2">
            <!--<div class="form-group">
                <img class="img-fluid" :src="form.firstImage.url.replace('/0/0/0/0/', '/600/0/0/0/')" />
            </div>-->
            <template v-if="$v">
              <div class="form-group">
                <label for="title">标题</label>
                <input type="text" class="form-control" :class="{ 'is-invalid': $v.form.title.$error }" id="title" v-model.trim="$v.form.title.$model" placeholder="请输入标题">
                <div class="invalid-feedback">
                  <span v-if="!$v.form.title.required">请输入标题</span>
                  <span v-if="!$v.form.title.minLength">标题至少 {{$v.form.title.$params.minLength.min}} 个字符</span>
                  <span v-if="!$v.form.title.maxLength">标题最多 {{$v.form.title.$params.maxLength.max}} 个字符</span>
                </div>
              </div>
            </template>
            <div class="form-group">
              <label for="panoText">内容</label>
              <!--<textarea type="text" class="form-control" id="panoText" placeholder="输入内容" v-model:text="form.text"></textarea>-->
              <PellEditor v-model.trim="form.text" />
            </div>
            <!--<div class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitchLittlePlanetIntro" v-model="form.littlePlanetIntro">
              <label class="custom-control-label" for="customSwitchLittlePlanetIntro">小行星视图开场</label>
            </div>-->
            <div class="form-group">
              <label>天气效果</label>
              <select class="form-control" v-model="form.weather" @change="$emit('changeWeather')">
                <option v-for="i in weathers" :value="i.key">{{i.key}}</option>
              </select>
            </div>
            <div class="form-group">
              <button v-if="form.audio" type="button" class="btn btn-outline-primary mb-1" @click="showAudioLibDialog()">音乐:{{(form.audio||{}).name||null}}</button>
              <button v-else type="button" class="btn btn-outline-primary mb-1" @click="showAudioLibDialog()">选择背景音乐</button>
              <audio v-if="form.audio" class="form-control" :src="(form.audio||{}).url||null" controls></audio>
            </div>
            <template v-if="form.audio">
              <div class="form-group custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitchAudioAutoPlay" v-model.trim="form.audioAutoPlay">
                <label class="custom-control-label" for="customSwitchAudioAutoPlay">自动播放</label>
              </div>
              <div class="form-group custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitchAudioLoop" v-model.trim="form.audioLoop">
                <label class="custom-control-label" for="customSwitchAudioLoop">循环播放</label>
              </div>
            </template>
            <div v-if="false" class="form-group">
              <label>自动漫游模式</label>
              <select class="form-control" v-model="form.autoTourType">
                <option value=0>附近全景</option>
                <option value=1>本人当天</option>
              </select>
            </div>
            <div class="form-group">
              <label for="panoTitle">公共标签</label>
              <CheckBox checkprop="name"
                        :checkList="publicTags"
                        :selected="form.tags"
                        :onChange="tagsCheckOnChange">
              </CheckBox>
            </div>
            <div class="form-group">
              <label for="panoTitle">私有标签</label>
              <CheckBox checkprop="name"
                        :checkList="privateTags"
                        :selected="form.tags"
                        :onChange="tagsCheckOnChange">
              </CheckBox>
            </div>
            <div class="form-group">
              <button class="btn btn-outline-primary" @click="$refs.selectMapPoint.showModal()">选择地点</button>
            </div>
            <div v-if="form.gpsLng!=0||form.gpsLat!=0">
              <MapView :mark="{lat: form.gpsLat||0,lng:form.gpsLng||0}"></MapView>
            </div>
            <MapSelectedPointModal ref="selectMapPoint" :markerPoint="{lat: form.gpsLat,lng:form.gpsLng}" @submit="changeMarker">
            </MapSelectedPointModal>
            <div class="text-right">
              <button type="button" class="btn btn-primary btn-sm" @click="$refs.model.hideModal()">确定</button>
            </div>
          </div>
        </div>
      </div>
    </component>
    <template v-if="urls">
      <AudioLib ref="AudioLib" :params="params" :libUrl="urls.audioLib()" :onSelected="AudioLibSelected"></AudioLib>
    </template>
  </div>
</template>
<script>
  import PellEditor from '../../PellEditor'
  import UploadSingleBox from '../../UploadSingleBox'
  import CheckBox from '../../CheckBox'
  import AudioLib from '../../AudioLib'
  import MapView from '../../Map/MapView'
  import MapSelectedPointModal from '../../Map/MapSelectedPointModal'
  export default {
    components: {
      PellEditor,
      UploadSingleBox,
      CheckBox,
      AudioLib,
      MapView,
      MapSelectedPointModal
    },
    props: {
      config: {
        default() {
          return {}
        }
      },
    },
    data() {
      return {
        modelShow: null,
        modelHide: null,
        weathers: [],
        publicTags: [],
        privateTags: [],
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {},
      getComonent: {},
    },
    computed: {
      urls() {
        return this.publicData.urls
      },
      params() {
        return this.publicData.params
      },
      form() {
        return this.publicData.form || {}
      },
      $v() {
        return this.publicData.$v
      },
    },
    created() {
    },
    mounted() {
      this.loadWeatherTypes()
      this.loadTags()
      this.$emit('ready', {
        vm: this,
        events: {
          proTips: {
            target: 'proTips',
            name: 'show'
          },
          changeWeather: {
            target: 'Data',
            name: 'formChage',
            arg: 'weather',
          },
        },
        funcs: {
          show: this.show,
        }
      })
    },
    destroyed() {
    },
    methods: {
      modelReady({ funcs }) {
        this.modelShow = funcs.show
        this.modelHide = funcs.hide
      },
      show() {
        if (this.modelShow) {
          this.modelShow()
        } else {
          alert('模态框还没有准备好')
        }
      },
      onhide() {
        this.$emit('next')
      },
      loadWeatherTypes() {
        if (!this.urls) {
          setTimeout(this.loadWeatherTypes, 300)
          return
        }
        this.$axios.get(this.urls.weatherTypes())
          .then((response) => {
            //console.log(response)
            this.weathers = response.data
          })
          .catch(function (error) {
            console.log(error)
          })
      },
      loadTags() {
        if (!this.urls) {
          setTimeout(this.loadTags, 300)
          return
        }
        var tagtype = '全景';
        this.$axios.get(this.urls.publicTags(), { params: { type: tagtype} })
          .then((response) => {
            this.publicTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.$axios.get(this.urls.privateTags(), { params: { type: tagtype, ...this.params } })
          .then((response) => {
            this.privateTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      showAudioLibDialog() {
        this.$refs.AudioLib.showDialog();
      },
      AudioLibSelected(select) {
        this.form.audio = select;
      },
      logoUploadFinished(file) {
        console.log(file)
        this.form.logo = file.logo;
      },
      deleteLogo() {
        this.$axios.delete(this.urls.panoLogo()).then(() => {
          this.form.logo = null
        })
      },
      changeMarker(p) {
        console.log('changeMarker', p)
        this.form.gpsLat = p.lat
        this.form.gpsLng = p.lng
      },
      tagsCheckOnChange(list) {
        console.log(list)
        this.form.tags = [...list]
      },
    },
  }
</script>
<style scoped>
  .introText {
    color: white;
    text-align: justify;
  }
</style>
